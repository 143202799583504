<template>

  <div class="move is-full-height is-overflow-y-scroll is-flex is-flex-direction-column is-justify-content-center is-overflow-y-scroll">
  
    <back-button class="ml-2"/> 

  

  <div class="is-flex is-flex-direction-row is-justify-content-center mb-2">
    <AddDingLocation :item="pseudoItem" @locationUpdate="handleLocationUpdate"/>
  </div>

  

  <div class="is-fixed is-z-3 is-bottom-0 is-full-width">
      <button class="button m-2 mt-3 mb-4 has-background-purple-1 is-rounded has-text-light has-border-black" @click="handleMoveItem">MOVE ITEM(S)</button>
      
      <button class="button m-2 mt-3 mb-4 has-background-light is-rounded has-text-dark has-border-black" @click="$router.push('/')">CANCEL</button>
  </div>
  
  </div>
  
</template>

<script>
import AddDingLocation from '@/components/ItemForm/addDingLocation.vue'
import BackButton from '@/components/Template/BackButton.vue';

import { ref, onMounted, onUnmounted} from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute, useRouter} from "vue-router"

export default {
  name: 'AddDing',
  components: {
    BackButton,
    AddDingLocation
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const pseudoItem = ref({atLocation:null,insideof:null});
    const items2move = ref([]);

    onMounted(()=>{
      store.dispatch("hideTabBar");

      if (route.query.itemsSource && route.query.itemsSource.toLowerCase() == "basket"){
        items2move.value = store.state.data.basket;
        console.log("is basket",items2move.value);
      }
      else{
        items2move.value = [store.state.selected.item];
        console.log("is item.selected",items2move.value);
      }
    })

    onUnmounted(()=>{
      store.dispatch("showTabBar");
    })

    const handleLocationUpdate = async (e)=>{
      console.log("storage/location update event received",e);
      if (!e.insideof && !e.atLocation){
        console.log("location empty. will empty field in item ");
        pseudoItem.value.insideof = null;
        pseudoItem.value.atLocation = null;
        return;
      }
      if (e.insideof){
        pseudoItem.value.insideof = e.insideof;
        pseudoItem.value.atLocation = null;
      }
      else if (e.atLocation){
        pseudoItem.value.insideof = null;
        pseudoItem.value.atLocation = e.atLocation;
      }
      pseudoItem.value["location.insideofArray"] = e["location.insideofArray"]
    }

    const handleMoveItem = async ()=>{
      console.log("moveItems with target location/storage:", pseudoItem.value);
      
      let items = items2move.value.map((i)=>{
        let newitem = Object.assign({id:i.id},pseudoItem.value);
        return newitem
      })
      console.log("items as moved",items);
      let result = await store.dispatch("moveItems",{items}) // CONTINUE HERE: TODO: Add moveItems action
      if (result.status == 200){
        // clear/reload or show success screen after clearing
        pseudoItem.value = {};
        router.go(-1);
        //router.push(('/success'))
      }
    }

 

    return {
        handleMoveItem,
        handleLocationUpdate,
        pseudoItem,
        items2move,
    }
  }
}
</script>